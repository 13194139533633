.career-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
    height: 100vh;
    box-sizing: border-box;
    padding-left: calc(65px + 20px);

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;

    .text-zone {
        text-align: center;
        margin-bottom: 2rem;

        h1 {
            font-size: 53px;
            font-family: 'Coolvetica';
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            left: 0;
        }

        .text-animate-hover {
            &:hover {
                color: #fff;
            }
        }
    }

    .experiences-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .experience-block {
        display: flex;
        background-color: #333;
        color: #fff;
        padding: 1.5rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        margin-bottom: 2rem;
        max-width: 800px;
        width: 100%;
    }

    .experience-img-block {
        flex: 1;
        padding-right: 1rem;
        justify-content: center;
        align-items: center;
        display: flex;

        img {
            height: auto;
            border-radius: 8px;

            &.novascan-image {
                width: 80%;
            }

            &.ura-image {
                width: 80%;
            }

            &.dalsa-image {
                width: 100%;
            }

            &.gem-image {
                width: 100%;
            }

            &.attorney-image {
                width: 70%;
            }
        }
    }

    .experience-text-block {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .experience-institution {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
            color: #ffd700;
        }

        .experience-position {
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
            display: flex;
            justify-content: space-between;
        }

        .experience-body {
            ul {
                padding-left: 20px;
                list-style-type: disc;

                li {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .text-zone {
            margin-bottom: 1rem;

            h1 {
                font-size: 40px;
            }
        }

        .experience-block {
            flex-direction: column;
            padding: 1rem;
        }

        .experience-img-block {
            padding-right: 0;
            margin-top: 1rem;
            margin-bottom: 2rem;

            img {
                width: 100%;
                max-width: 50%;
            }
        }

        .experience-text-block {
            .experience-institution {
                font-size: 0.9rem; 
            }

            .experience-position {
                font-size: 0.9rem; 
            }

            .experience-body {
                font-size: 0.9rem;
                ul {
                    padding-left: 15px;

                    li {
                        margin-bottom: 0.3rem;
                    }
                }
            }
        }
    }
}