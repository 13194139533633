.home-page {

    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        /*&::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            animation: fadeInToOpacity 1s 1.7s backwards;
            opacity: 0.6;
        }*/

        img {
            width: 60px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #ffd700;
        margin-top: 5px;
        font-weight: 400;
        font-size: 16px;
        font-family: sans-serif;
        letter-spacing: 3px;
        opacity: 0.7;
        
        /* &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 16px;
            position: absolute;
            margin-top: 50px;
            left: 15px;
            opacity: 0.6;
        }*/
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400px;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }

    @media (max-width: 768px) {
        .text-zone {
            align-items: center;
            text-align: center;
            max-width: fit-content;
            flex-direction: column;
            padding-left: 25%;

            h1 {
                font-size: 26px;
            }

            h2 {
                font-size: 15px
            }
        }
    }
}