.logo-container {
  z-index: 0;
  width: 400px; 
  height: 400px; 
  opacity: 0;
  position: absolute;
  top: 50%;
  right: -58%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .outline-logo {
    position: absolute;
    width: 420px;  /* Slightly larger than the container */
    height: 420px; /* Slightly larger than the container */
    stroke: #ffd700;
    stroke-width: 5;
    fill: none;
    top: -10px; /* Adjust position to be outside the image */
    left: -10px; /* Adjust position to be outside the image */
  }

  .solid-logo {
    width: 100%;
    height: 100%;
    border-radius: 50%; 
    object-fit: cover; 
    opacity: 0;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  
  .logo-container{
    display: none;
  }
}
